.ant-notification {
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 100%;
    max-width: calc(100vw - 16px);
}
.ant-notification-notice.system-message {
    padding: 32px 16px;
}
.ant-notification-notice.system-message .ant-notification-notice-with-icon {
    display: flex;
    align-items: center;
}
@media (min-width: 768px) {
    .ant-notification-notice.system-message .ant-notification-notice-content {
        text-align: center;
    }
    .ant-notification-notice.system-message .ant-notification-notice-with-icon {
        display: inline-flex;
    }
}
.ant-notification-notice.system-message .ant-notification-notice-icon {
    margin-right: 16px;
}
.ant-notification-notice.system-message .anticon svg {
    fill: #ffffff;
}
.ant-notification-notice.system-message .ant-notification-notice-icon svg {
    height: 32px;
    width: auto;
}
.ant-notification-notice.system-message .ant-notification-notice-message {
    color: #ffffff;
    margin-bottom: 0;
    text-align: left;
    font-family: 'Open Sans';
}
.ant-notification-notice.system-message .ant-notification-notice-description {
    display: none;
    visibility: hidden;
}

.ant-notification-notice.system-message-info {
    background-color: #35c4fc;
}
.ant-notification-notice.system-message-success {
    background-color: #0fb855;
}
.ant-notification-notice.system-message-error {
    background-color: #f33950;
}

.ant-notification-notice.system-message.ant-notification-fade-enter.ant-notification-fade-enter-active {
    animation-name: SystemMessageFadeIn;
}

.ant-message {
    top: 8px;
}

.ant-message-notice-content {
    width: 100%;
    padding: 0;
    text-align: left;
}

.ant-message-custom-content {
    display: flex;
    align-items: center;

    color: #ffffff;
    font-family: 'Open Sans';
    padding: 32px 16px;
}

.ant-message .anticon {
    margin-right: 16px;
}

.ant-message-custom-content svg {
    fill: #ffffff;
    height: 32px;
    width: auto;
}

.ant-message-success {
    background-color: #0fb855;
}

.ant-message-info {
    background-color: #35c4fc;
}

.ant-message-error {
    background-color: #f33950;
}

.ant-select-dropdown {
    padding: 0;
}
.ant-select-dropdown .ant-select-item-option {
    height: 48px;
    padding: 4px 8px;
    line-height: 40px;
}

@media (min-width: 600px) {
    .ant-message-custom-content {
        justify-content: center;
        padding: 32px 64px;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

/* Custom animation for SystemNotifications */
@-webkit-keyframes SystemMessageFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes SystemMessageFadeIn {
    0% {
        opacity: 0;
        max-height: 0;
    }
    100% {
        opacity: 1;
        max-height: 150px;
    }
}
