@import '~antd/dist/antd.css';
@import 'style/antDesign.css';
@import 'style/common.css';

@font-face {
    font-family: Gebauer-Medium;
    src: url('style/fonts/Gebauer-Medium.otf') format('opentype');
}

@font-face {
    font-family: Gebauer-Regular;
    src: url('style/fonts/Gebauer-Regular.otf') format('opentype');
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.environment-header {
    width: 100%;
    height: 24px;
    background-color: #ffecaa;
}
